@media screen and (min-width: 100px) and (max-width: 600px) {
  .notification-list {
    width: 100vw !important;
    max-height: 80vh !important;
    min-height: 10vh;
    overflow-y: scroll;
    margin-left: 0.6vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 980px) {
  .notification-list {
    width: 50vw !important;
    max-height: 80vh !important;
    min-height: 10vh;
    overflow-y: scroll;
    margin-left: 0.6vw;
  }
}
